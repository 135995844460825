<template>
	<div id="listfarm">
		<v-container>
			<v-row align="center" justify="center">
				<h1>ฟาร์มของฉัน</h1>
				<div class="mx-2"></div>
				<v-icon large @click="logout">{{ icons.mdiAccount }}</v-icon>
			</v-row>
			<br />
			<v-row no-gutters>
				<v-col
					offset-xl=""
					v-for="farmdata in farmlist"
					:key="farmdata.farm_id"
					lg="3"
					md="4"
					sm="6"
					cols="6"
					align="center"
				>
					<v-card
						v-if="farmdata.active == 1"
						class="pa-2"
						@click="go_farmdetail(farmdata)"
						style="position: relative"
					>
						<v-icon
							large
							color="error"
							v-if="farmdata.noti"
							style="top: 0; right: 0; position: absolute"
							>{{ icons.mdiInformationOutline }}</v-icon
						>
						<v-img
							:src="img"
							height="150px"
							width="auto"
							contain
						></v-img>
						<v-card-subtitle>
							<span class="font-weight-bold">{{
								farmdata.farm_name
							}}</span>
							<br />
							<span class="orange--text"
								>{{ farmdata.greenhouse }}/
								{{ farmdata.table }}</span
							>
							<br />
							<span>โรงเรือน/โต๊ะปลูก</span>
						</v-card-subtitle>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
		<v-col md="8" offset-md="2" v-if="notifarm">
			<v-alert
				border="top"
				color="red"
				dense
				outlined
				prominent
				text
				type="info"
				dismissible
				>เชียงใหม่ฟาร์มน้าไม่ไหล</v-alert
			>
		</v-col>
	</div>
</template>

<script>
import {
	mdiAccount,
	mdiPencil,
	mdiShareVariant,
	mdiDelete,
	mdiInformationOutline,
} from "@mdi/js";

export default {
	name: "listfarm",
	components: {},
	data: () => ({
		farmlist: [],
		icons: {
			mdiAccount,
			mdiPencil,
			mdiShareVariant,
			mdiDelete,
			mdiInformationOutline,
		},
		notifarm: false,
		img: require("../assets/smart-farm.png"),
	}),
	created: function () {
		window.scrollTo(0, 0);
		var self = this;
		var config = {
			method: "get",
			url: "https://cool-agri.com/hydroponic_portal/api/v1/farm",
			headers: {
				Authorization: localStorage.getItem("jwt"),
				"Content-Type": "application/json",
			},
			params: { user_id: 1 },
		};
		this.axios(config)
			.then(function (response) {
				if (response.data.success == 1) {
					self.farmlist = response.data.data;
				}
			})
			.catch(function (error) {
				console.log(error);
				this.$router.push("/");
			});
	},
	computed: {},

	watch: {},

	methods: {
		go_farmdetail(farm_data) {
			this.$session.set("farm_data", farm_data);
			this.$router.push("/farmdetail");
		},
		logout() {
			this.$swal.fire({
				position: "center",
				icon: "success",
				title: "ออกจากระบบแล้ว",
				showConfirmButton: false,
				timer: 1500,
			});
			localStorage.clear();
			this.$session.clear();
			this.$router.push("/");
		},
	},
};
</script>

<style></style>
